.yml-format p {
    color: black;
    margin: 0;
}
.ie-label {
    width: 100%;
}
.ie-info-label-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.ie-info-label {
    font-size: 17px;
}
.ie-info-btn {
    margin-left: 2.5px;
    margin-right: 2.5px;
    height: 1.5rem !important;
    width: 1.5rem !important;
    padding: 0 !important;
}
.ie-example-container {
    display: flex;
    justify-content: space-between;
}
.ie-example {
    display: flex;
    flex-direction: column;
}
.ie-example label {
    font-weight: 600;
}
.ie-textarea {
    overflow-y: scroll;
    resize: none;
}
