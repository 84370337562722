.botTrainItem-container {
    margin: 4rem 0;
    display: flex;
}

.botTrainItem-items {
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.botTrainItem-list-container {
    width: 30%;
}
.botTrainItem-list-container select {
    width: 100%;
    overflow: auto;
}
.botTrainItem-list-container label {
    width: 100%;
}
.botTrainItem-list {
    width: 100%;
}

.botTrainItem-operations {
    flex-direction: row;
}

.botTrainItem-operations button {
    width: 2rem;
    height: 2rem;
    background-color: DodgerBlue;
    border: none;
    color: white;
    /* padding: 12px 16px;  */
    padding: 0;
    margin: 1rem;
    font-size: 16px;
}
.botTrainItem-items button {
    border-radius: 4px;
    cursor: pointer;
}

.new-botTrainItem {
    border: 1px solid black;
}

.new-botTrainItem__invisible {
    display: none;
}

.new-botTrainItem label {
    margin-bottom: 0;
    margin-right: 0.5rem;
}

.hint {
    color: red;
    margin: 0;
}
.new-botTrainItem label {
    width: 12%;
    min-width: 120px;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

.form-control option.deleted {
    background: #f86c6b;
    color: #fff;
}

.form-control option.new {
    background: #33cc99;
    color: #fff;
}

.form-control option.updated {
    background: #fc9e35;
    color: #fff;
}

.form-control option.disabled {
    background: #9b9ca1;
    color: #fff;
}
option:not(:checked) {
    background-color: #fff;
}
.form-control option:checked {
    background: rgb(0, 35, 68);
    color: #fff;
}
