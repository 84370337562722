/* .p-datatable {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.3);
    -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.3);
    box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.3);
} */

body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-datatable-tbody > tr > td,
body .p-paginator {
    border: none;
}

body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
}

body .p-datatable .p-datatable-thead > tr > th {
    color: #333;
    background-color: transparent;
    border-bottom: 2px solid #cccccc;
}

/* body .p-datatable .p-datatable-tbody > tr:nth-child(odd) {
    background: #fbfbfb;
} */

body .p-datatable .p-datatable-tbody > tr.datatable-row-highlighter {
    background: #ffe666;
}

body .p-datatable .p-datatable-tbody > tr.datatable-row-highlighter-delete {
    background: #f86c6b;
}

body .p-datatable .p-datatable-tbody > tr.datatable-row-highlighter-new {
    background: #33cc99;
}

body .p-datatable .p-datatable-tbody > tr.datatable-row-highlighter-delete,
body .p-datatable .p-datatable-tbody > tr.datatable-row-highlighter-new {
    color: #fff;
}

body .p-datatable .p-datatable-tbody > tr {
    background: transparent;
}

body .p-paginator {
    background: transparent;
    padding: 10px;
}

body .p-datatable .p-datatable-header {
    border: none;
    background-color: #ebebeb;
}

.reviewgrid-responses {
    width: 12%;
}

.reviewgrid-confidence {
    width: 12%;
}

.reviewgrid-users {
    width: 20%;
}

.reviewgrid-ts {
    width: 22%;
}

.reviewgrid-actions {
    width: 25%;
}

.historygrid-responses {
    width: 10%;
}

.historygrid-texttospeech {
    width: 10%;
}

.historygrid-confidence {
    width: 10%;
}

.historygrid-users {
    width: 15%;
}

.historygrid-ts {
    width: 15%;
}

.coordinates-location-name {
    width: 25%;
}

.coordinates-lat-lon {
    width: 12%;
}

.coordinates-view-maps {
    width: 8%;
}

.coordinates-user {
    width: 10%;
}

.coordinates-action {
    width: 10%;
}

.entities-grid {
    overflow-y: scroll;
    max-height: 300px;
    font-size: 14px;
}

.livechat-id {
    width: 6%;
}

.livechat-meta {
    width: 11%;
}

.livechat-ts {
    width: 20%;
}

.livechat-messages {
    width: 50%;
}

.livechat-action {
    width: 18%;
}

@media (min-width: 1360px) {
    .reviewgrid-responses {
        width: 10%;
    }
    .reviewgrid-texttospeech {
        width: 10%;
    }
    .reviewgrid-confidence {
        width: 10%;
    }
    .reviewgrid-users {
        width: 22%;
    }
    .reviewgrid-ts {
        width: 15%;
    }
    .reviewgrid-actions {
        width: 20%;
    }
    .historygrid-users {
        width: 20%;
    }
}

@media (max-width: 1600px) {
    .entities-grid {
        max-height: 250px;
    }
}
