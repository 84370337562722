body .p-button {
    background-color: #63c2de;
    border: 1px solid #63c2de;
}

body .p-button:enabled:hover {
    background-color: #43b6d7;
    border-color: #39b2d5;
}
.p-datatable-thead .p-column-title {
    overflow-wrap: break-word;
}

.p-datatable .p-sortable-column-icon {
    display: inline;
}

.list-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.list-actions a {
    cursor: pointer;
    color: #43b6d7 !important;
}

.list-actions a:hover {
    text-decoration: underline;
}

.submenu .nav {
    background: #f4f4f4;
}

.submenu .nav-item {
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
}

.image-link-wrap {
    padding: 10px;
    border: 1px solid #33cc99;
    background: #eafff8;
}

.image-link-wrap p {
    word-break: break-all;
}

.p-toast-top-right {
    top: 80px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: normal;
}

.p-toast .p-toast-message.p-toast-message-error {
    border-left: 5px solid #9b0000;
    color: #9b0000;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #9b0000;
}

.p-sidebar {
    z-index: 999999 !important;
}

.p-sidebar-mask {
    z-index: 999998 !important;
}

.p-divider.p-divider-vertical:before {
    border-left: 1px #c8c8c8 solid;
}

.p-fileupload .p-fileupload-files button,
.p-fileupload .p-fileupload-buttonbar button:last-child,
.p-fileupload .p-fileupload-buttonbar button:last-child:hover[disabled] {
    background-color: var(--danger);
    border-color: var(--danger);
}
.p-fileupload .p-fileupload-files button:hover,
.p-fileupload .p-fileupload-buttonbar button:last-child:hover {
    background-color: #f63c3a;
    border-color: #f63c3a;
}

/* fix the jumping checkbox behavior */
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    position: absolute;
}

@supports (-ms-ime-align: auto) {
    /*only for edge*/
    body .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
        border: 1px solid rgba(234, 234, 234, 0.63);
    }
}

@media screen and (max-width: 40em) {
    .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
        min-width: 50%;
    }
}
