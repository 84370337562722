.main-layout {
    padding: 62px 0 0 90px;
    transition: 0.2s;
}
.main-layout.collapsed {
    padding: 62px 0 0 261px;
}
main.main-layout {
    width: 100%;
    min-height: 100%;
}
main .main-pages-container {
    box-sizing: border-box;
    margin: 0.2rem;
    border-radius: 2px;
    margin-bottom: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    .fullExpand {
        margin-left: 0 !important;
    }
}
@media (min-width: 900px) and (max-width: 1081px) {
    .expandHeight {
        padding-bottom: 60px;
    }
}
