.form-control input.text-input:focus {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}

div.text-search-input input.text-input[disabled] {
    cursor: not-allowed !important;
    pointer-events: unset;
}
