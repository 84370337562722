.wm-change {
    margin: 0;
}

.wm-url {
    color: #808080;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
