.bot-train-main-container {
    margin: 0;
}
.bot-train-main-container > h6 {
    margin: 0.8rem 0;
    padding-left: 5rem;
    font-size: 1rem;
}
.bot-train__change {
    margin: 0.3rem;
}
.bot-train-main-container button {
    margin: 0 0.6rem;
    padding: 2px;
    width: 142px;
    border: 1px solid;
    border-radius: 3px;
    cursor: pointer;
}

.bot-train__change-panel {
    margin: 0.4rem 1.5rem;
    padding: 1rem;
    border: 1px solid;
}
.bot-train__change-panel h5 {
    color: #0573c7d3;
    margin: 0;
}
.bot-train__change-panel-list {
    width: 45%;
}
.bot-train__change-panel-modif-lists {
    display: flex;
    justify-content: space-around;
    padding: 1.5rem;
    /* width: 450; */
}
.bot-train__change-panel-modif-lists select {
    width: 90%;
}

.bot-train-approve-reject-container {
    display: flex;
    justify-content: flex-end;
}

.intent-change-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    flex: 1;
    box-shadow: -1px -1px 23px -20px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px -1px 23px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px -1px 23px -20px rgba(0, 0, 0, 0.75);
}

.intent-change-box p {
    margin-bottom: 5px;
    padding: 3px;
}

.intent-change-box p.new {
    background: #ecfdf0;
}

.intent-change-box p.deleted {
    background: #fbe9eb;
}

.label-intent-deleted {
    color: #f86c6b;
}

.label-intent-new {
    color: #33cc99;
}

.label-intent-updated {
    color: #FAA945;
}

.training-available {
    color: #33cc99;
}

.training-inprogress {
    color: #f86c6b;
}
