.messageBody {
    padding: 10px;
    border-radius: 15px;
    box-shadow: 1px 1px 5px #c8c8c8;
    overflow-wrap: anywhere;
}

.timestamp {
    font-weight: 600;
    color: #999999;
    padding: 0 5px;
}

.sendingStatus {
    font-weight: 700;
    font-size: 0.9rem;
}

.awaiting {
    color: #999999;
}
.success {
    color: var(--light-blue);
}
.failed {
    color: var(--danger);
}