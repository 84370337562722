.tile {
    margin-bottom: 20px;
}

.tile > div {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.tile .head {
    border-bottom: 3px solid #33cc99;
    height: 70px;
    padding: 0 10px;
    line-height: 0.95rem;
    display: flex;
    align-items: center;
}

.tile .body {    
    padding: 10px;
    display: flex;
}

.tile .value {    
    font-size: 2rem;
    margin-right: 10px;
}