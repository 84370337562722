.pending-changes-banner {
    background-color: #ffe666;
    padding: 10px;
    font-size: 1.5em;
}

.pending-changes-banner-bg {
    background-color: #ffe666;
}

.pending-deletion-banner-bg {
    background-color: rgba(248, 108, 107, 0.6);
}
