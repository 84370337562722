.disable-intent-dialog li.p-listbox-item {
    overflow: visible;
}

.disable-intent-dialog p.deleted {
    background: #f86c6b;
    color: #fff;
}

.disable-intent-dialog p.new {
    background: #33cc99;
    color: #fff;
}

.disable-intent-dialog p.updated {
    background: #fc9e35;
    color: #fff;
}

.disable-intent-dialog .p-highlight {
    color: #fff;
}

.disable-intent-dialog-wrapper .p-dialog-content {
    flex: 1;
}

.disable-intent-dialog .p-listbox .p-listbox-list li.p-listbox-item {
    padding-top: 2px;
    padding-bottom: 2px;
}
