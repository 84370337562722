.ver_num {
    margin: 0;
    font-size: 12px;
}


@supports (-ms-ime-align: auto) {  /*only for edge*/
    .expand {
        width: 100% !important;
    }

    @media (min-width: 992px) {
        .app-footer {
            width: calc(100% - 200px);
        }
    }
}


@media (min-width: 625px){
    .expand{
        margin-left: 0 !important;
    }
}
@media (max-width: 900px) {
    .shrinkText{
        display: block;
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 1080px) {
    .shrinkTextSidebarVisible{
        display: block;
        width: 100%;
        text-align: center;
    }
}

.shrinkText > a {
    color: #70bcf7 !important;
}
