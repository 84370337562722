#user-name-edit:hover {
    cursor: pointer;
}
.self-edit-list {
    display: initial;
    color: #d2d2d2;
    text-decoration: none;
    list-style: none;
    z-index: 1003;
    position: absolute;
    background-color: #1b1d23;
    right: 0;
    top: 54px;
    padding:4px 0 4px 0;
}
.hide-self-edit-list{
    display: none !important;
}
.self-edit-list-a{
    text-decoration: none;
}
.user-edit-icon {
    padding-right: 5px;
}
#lock-icon {
    padding-left: 2px;
}
#anchor-lock-li {
    padding-left: 3px;
}
.self-edit-list-li{
    width: 160px;
    padding-left: 10px;
    padding-bottom: 3px;
    cursor: pointer;
}
.self-edit-list-li:hover{
    background-color: #3b3e46;
}
.edit-user-caret-down-icon {
    margin-left: 5px;
    cursor: pointer;
}
.overlay-panel {
    right: 15px !important;
    top: 95% !important;
    border: 1px solid #33cc99 !important;
    background-color: #1b1d23 !important;
}
.show-overlay-panel {
    display: block !important;
}
.hide-overlay-panel {
    display: none !important;
}
.overlay-panel-text {
    margin: auto;
    padding: 10px 0px 7px 0px;
}
@media (max-width: 991px) {
    .overlay-panel {
        display: none !important;
    }
}
body .p-overlaypanel .p-overlaypanel-close{
    background-color: #33cc99 !important;;
}
body .p-overlaypanel::after, .p-overlaypanel::before {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #33cc99 !important;
    right: 3rem !important;
    left: unset !important;
}
body .p-overlaypanel .p-overlaypanel-close {
    background-color: transparent !important;
    color: #d2d2d2 !important;
    width: 1.538em;
    height: 1.538em;
    font-weight: lighter !important;
    line-height: 1.538em;
    text-align: center;
    position: absolute;
    top: 0px !important;
    right: 2px !important;
    border-radius: 50%;
}
.p-overlaypanel-close>.pi-times:before {
    content: "\2A09" !important;
}
.p-overlaypanel-close>.pi {
    font-size: 11px !important;
}

.view-as-user-name {
    color: #33cc99 !important;
}
