#chat-panel .chat-history .chat-messages p {
    overflow-wrap: break-word;
}
.live-chat-form .p-autocomplete,
.live-chat-form .p-autocomplete-input {
    width: 100%;
}
.autocomplete-template-suggestions {
    height: 150px;
}
.autocomplete-template-suggestions,
.autocomplete-template-suggestions .p-autocomplete-item {
    padding: 5px 5px 0px 5px !important;
}
.live-chat-form .p-autocomplete-loader {
    display: none;
} 
.autocomplete-template-suggestions .p-highlight {
    background-color: #c8c8c8 !important;
}