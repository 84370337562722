.page-main-container {
    display: flex;
    flex-direction: column;
}

.btn-send-apprvl {
    width: 10%;
    margin: 2rem;
    padding: 0.2rem;
    background-color: rgb(221, 66, 66);
    align-self: flex-end;
    border-radius: 3px;
}

.p-listbox .intent-list .p-listbox-list li {
    padding: 1px 0;
}

.p-listbox .intent-list .deleted {
    background: #f86c6b;
    color: #fff;
}

.p-listbox .intent-list .new {
    background: #33cc99;
    color: #fff;
}

.p-listbox .intent-list .updated {
    background: #fc9e35;
    color: #fff;
}

.p-listbox .intent-list .disabled {
    background: #9b9ca1;
    color: #fff;
}

.p-listbox .intent-list .p-listbox-item:focus {
    outline: none;
    box-shadow: none;
}

.p-listbox .intent-list .p-listbox-item:hover {
    background: none !important;
}

.p-listbox .intent-list .p-listbox-item:focus div,
.p-listbox .intent-list .p-listbox-item:hover div,
.p-listbox .intent-list .p-listbox-item div.selected {
    outline: black solid 1px;
}
.p-listbox .intent-list .p-listbox-item div.selected {
    background: var(--primary-color);
    color: white;
}

.p-listbox .intent-list .p-listbox-item div {
    outline: none;
    margin: 1px;
}
