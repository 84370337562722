.container {
    position: relative;
    padding: 2px 5px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: opacity 0.3s;
}

.textHidden {
    z-index: -1;
    opacity: 0;
}

.textHover {
    z-index: 2000;
    opacity: 1;
}

.hoverStyle {
    background-color: #d6d6d6;
    padding: 0.1rem 0.4rem;
    border-radius: 0.2rem;
}
