.scrollToBottomButton {
    position: absolute;
    bottom: 5px;
    left: 50%;
    padding: 15px 20px;
    border-radius: 15px;
    box-shadow: 1px 1px 5px #c8c8c8;
    cursor: pointer;
    background-color: #898989;
}

.unreadMessages {
    position:absolute;
    top: -12px;
    left: -5px;
    background-color: red;
    padding: 2px 8px;
    color: white;
    border-radius: 15px;
}