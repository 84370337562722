.live-chat-template-card {
    cursor: pointer;
}

.live-chat-template-card:hover {
    background: #d6d5d2;
}

.live-chat-selected-template-card {
    background: #f1f0ec;
}

.live-chat-selected-template-description {
    font-style: italic;
    color: #b1b1b1;
}

.live-chat-selected-template-name {
    font-weight: 600;
}
