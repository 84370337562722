.live-chat-card {
    cursor: pointer;
}

.live-chat-card:hover {
    background: #d6d5d2;
}

.selected-live-chat-card {
    background: #f1f0ec;
}
.live-chat-card-client-name {
    font-weight: 600;
}

.odd-line {
    background-color: #fbfbfb;
}

.live-chat-init-message-count {
    font-style: italic;
    color: #b1b1b1;
}

.live-chat-info-tag {
    background-color: #b1b1b1;
    color: white;
    font-weight: unset;
    padding: 0.1rem 0.2rem;
}
