.app-sidebar {
    position: relative;
    z-index: 999;
}

.app-sidebar nav {
    background: #ba0202;
    position: fixed;
    top: 63px;
}

.app-sidebar div.scrollable-sidebar[role="menu"]:first-of-type {
    height: calc(100vh - 63px - 22px - 64px - 16px);
    overflow-y: auto;
    overflow-x: hidden;
}

.main-context-menu {
    position: fixed;
    top: 50px;
    z-index: 999999;
    min-width: 300px;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    border: 1px #bbb solid;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 15%);
}

.main-context-menu .item {
    display: block;
    text-decoration: none;
    color: #222;
    font-size: 13px;
    line-height: 15px;
    padding: 10px 24px;
    cursor: pointer;
}

.main-context-menu .item:hover {
    background: #eee;
}

.item-visible {
    display: block !important;
}

/* menu is in compact form  */
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .menu-header {
    cursor: default;
    position: relative;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .menu-header:hover {
    background: #fff;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
    .sidenav---sidenav-subnav---1EN61
    > .sidenav---sidenav-subnavitem---1cD47:hover
    > .menu-header.sidenav---navitem---9uL5T {
    color: #f9dcdd;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .menu-header::before {
    content: '';
    position: absolute;
    left: 5px;
    bottom: 10px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-bottom: 12px solid black;
}

/* remove before element */
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
    .sidenav---sidenav-subnav---1EN61
    > .sidenav---sidenav-subnavitem---1cD47::before {
    content: unset;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
    .sidenav---sidenav-subnav---1EN61
    > .sidenav---sidenav-subnavitem---1cD47
    > .menu-header::before {
    content: unset;
}

/* menu is contracted expanded*/
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    max-width: 240px;
    user-select: none;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
    .sidenav---sidenav-subnav---1EN61
    > .sidenav---sidenav-subnavitem---1cD47
    > .menu-header {
    cursor: default;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
    .sidenav---sidenav-subnav---1EN61
    > .sidenav---sidenav-subnavitem---1cD47
    > .menu-header
    div {
    border-bottom: 0px solid #f9dcdd;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL
    .sidenav---sidenav-subnav---1EN61
    > .sidenav---sidenav-subnavitem---1cD47
    > .menu-header
    div::before {
    content: '';
    position: absolute;
    left: 3px;
    bottom: 9px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    /* border-right: 15px solid transparent; */

    border-bottom: 12px solid #f9dcdd;
}

.third-level-nav-item {
    margin-left: 20px;
}

@media (max-width: 992px) {
    .show {
        margin-left: 0;
    }
}
