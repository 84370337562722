.permissions-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-right: 1px solid #ccc;
    min-height: 500px;
}

.permissions-list button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
    outline: none;
}

.permissions-list li.active button,
.permissions-list button:hover {
    border-bottom: 1px solid #000;
}

.addNewUserGroupWrap {
    text-align: right;
    margin-top: -50px;
}

#userGroupPermissions > button {
    margin-top: 40px;
}

#userGroupPermissions > label {
    display: inline-block;
    width: 50%;
    padding: 5px;
}
